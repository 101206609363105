import { Link } from '@remix-run/react'
import { type NavigationLink } from '../../types/graphql.ts'

export default function Footer({ links }: { links: NavigationLink[] }) {
	return (
		<footer className="bg-copper-300 w-full pb-16 pt-20">
			<div className="container flex flex-col space-y-[30px]">
				<img
					src="/assets/logo-green.svg"
					alt="Settoon Capital"
					className="w-[321px]"
					width="321"
					height="74"
				/>
				<hr className="bg-copper-100 h-[1px] w-full border-0" />
				<nav>
					<ul className="text-copper-100 md:divide-copper-100 md:not-first:pl-[30px] flex flex-col space-y-6 text-left uppercase leading-4 tracking-wide last:pr-[30px] md:flex-row md:space-x-[30px] md:space-y-0 md:divide-x md:text-center">
						{links.map((link) => (
							<li key={link.id}>
								<Link to={link.nodeUri} prefetch="intent">
									{link.title}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</div>
			<div className="text-copper-100 container mt-[60px] text-[14px] font-light leading-[24px]">
				&copy; Copyright 2022 Settoon Capital, All rights reserved.
			</div>
		</footer>
	)
}
